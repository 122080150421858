<template>
<div id="test">
    <model-obj
 
    :rotation="{
      x: -Math.PI / 2,
      y: 0,
      z: 0,
    }"
    src="/tree.obj"
  />
 </div>
</template>

<script>
 import { ModelObj  } from "vue-3d-model";

export default {
  components: { ModelObj  },
 
};
</script>

<style>
  #test{
    background-color: black;
  }
</style>